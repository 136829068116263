<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
      <feather-icon title="Manutenção" icon="ToolIcon" svgClasses="h-5 w-5 ml-2 hover:text-primary cursor-pointer" @click="maintenance" />
      <feather-icon title="Abastecimento" icon="BatteryChargingIcon" svgClasses="h-5 w-5 ml-2 hover:text-primary cursor-pointer" @click="fuelSupply" />
      <feather-icon title="Ordem de Serviço" icon="DollarSignIcon" svgClasses="h-5 w-5 ml-2 hover:text-primary cursor-pointer" @click="serviceOrder" />
      <feather-icon title="Editar" icon="Edit3Icon" svgClasses="h-5 w-5 ml-2 hover:text-primary cursor-pointer" @click="editRecord" />
      <feather-icon title="Excluir" icon="Trash2Icon" svgClasses="h-5 w-5 ml-2 hover:text-danger cursor-pointer" @click="deleteRecord" />
    </div>
</template>

<script>
export default {
  name: 'CellRendererActions',
  methods: {
    maintenance () {
      this.params.maintenance(this.params)// assim chamo a função de fora
    },
    fuelSupply () {
      this.params.fuelSupply(this.params)// assim chamo a função de fora
    },
    serviceOrder () {
      this.params.serviceOrder(this.params)// assim chamo a função de fora
    },
    editRecord () {
      this.$router.push(`/vehicle/edit/${this.params.data.id}`).catch(() => {})
    },
    deleteRecord () {
      this.params.delete(this.params)// assim chamo a função de fora
    }
  }
}
</script>
