export const localePt_Br = {
    selectAll: 'Selecionar tudo',
    selectAllSearchResults: 'Selecionar todos os resultados da busca',
    searchOoo: 'Buscar...',
    blanks: 'Vazios',
    noMatches: 'Sem coincidências',

    filterOoo: 'Filtro...',
    equals: 'Igual',
    notEqual: 'Diferente',

    // Number Filter
    lessThan: 'Menor que',
    greaterThan: 'Maior que',
    lessThanOrEqual: 'Menor ou igual',
    greaterThanOrEqual: 'Maior ou igual',
    inRange: 'No intervalo',
    inRangeStart: 'De',
    inRangeEnd: 'Até',

    // Text Filter
    contains: 'Contém',
    notContains: 'Não contém',
    startsWith: 'Começa com',
    endsWith: 'Termina com',

    // Date Filter
    dateFormatOoo: 'dd-mm-Yyyy',

    // Filter Conditions
    andCondition: 'E',
    orCondition: 'OU',

    // Filter Buttons
    applyFilter: 'Aplicar',
    resetFilter: 'Reiniciar',
    clearFilter: 'Limpar',
    cancelFilter: 'Cancelar',

    // Side Bar
    columns: 'Colunas',
    filters: 'Filtros',

    // columns tool panel
    pivotMode: 'Modo Pivô',
    groups: 'Grupos de colunas',
    rowGroupColumnsEmptyMessage: 'Soltar aqua para criar um grupo de colunas',
    values: 'Valores',
    valueColumnsEmptyMessage: 'Soltar aqui para juntar',
    pivots: 'Column Labels',
    pivotColumnsEmptyMessage: 'Soltar aqui para indicar etiquetas de coluna',

    // Header of the Default Group Column
    group: 'Grupo',

    // Other
    loadingOoo: 'Carregando...',
    noRowsToShow: 'Não há linhas para mostrar',
    enabled: 'Ativado',

    // Menu
    pinColumn: 'Fixar Coluna',
    pinLeft: 'Fixar à Esquerda',
    pinRight: 'Fixar à Direita',
    noPin: 'Soltar',
    valueAggregation: 'Agregação por valor',
    autosizeThiscolumn: 'Tamanho automático para esta coluna',
    autosizeAllColumns: 'Tamanho automático para todas as colunas',
    groupBy: 'Agrupar por',
    ungroupBy: 'Desagrupar por',
    resetColumns: 'Reiniciar Colunas',
    expandAll: 'Expandir Tudo',
    collapseAll: 'Fechar Tudo',
    copy: 'Copiar',
    ctrlC: 'Ctrl+C',
    copyWithHeaders: 'Copiar com cabeçalhos',
    paste: 'Colar',
    ctrlV: 'Ctrl+V',
    export: 'Exportar',
    csvExport: 'Exportar CSV',
    excelExport: 'Exportar Excel (.xlsx)',
    excelXmlExport: 'Exportar Excel antigo (.xml)',

    // Enterprise Menu Aggregation and Status Bar
    sum: 'Sum',
    min: 'Min',
    max: 'Max',
    none: 'Nada',
    count: 'Conta',
    avg: 'Média',
    filteredRows: 'Filtrado',
    selectedRows: 'Selecionado',
    totalRows: 'Total linhas',
    totalAndFilteredRows: 'Linhas',
    page: 'Página',
    more: 'Mais',
    to: 'Para',
    of: 'De',
    next: 'Próximo',
    last: 'Último',
    first: 'Primeiro',
    previous: 'Anterior',

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: 'Gráfico de Pivô e Modo Pivô',
    pivotChart: 'Gráfico de Pivô',
    chartRange: 'Gráfico de Intervalo',

    columnChart: 'Coluna',
    groupedColumn: 'Agrupado',
    stackedColumn: 'Empilhado',
    normalizedColumn: '100% Empilhado',

    barChart: 'Barra',
    groupedBar: 'Agrupado',
    stackedBar: 'Empilhado',
    normalizedBar: '100% Empilhado',

    pieChart: 'Pizza',
    pie: 'Pizza',
    doughnut: 'Donut',

    line: 'Linha',

    xyChart: 'X Y (Dispersão)',
    scatter: 'Dispersão',
    bubble: 'Bolha',

    areaChart: 'Área',
    area: 'Área',
    stackedArea: 'Empilhado',
    normalizedArea: '100% Empilhado',

    histogramChart: 'Histograma',

    // Charts
    pivotChartTitle: 'Gráfico de Pivô',
    rangeChartTitle: 'Gráfico de Intervalo',
    settings: 'Configuração',
    data: 'Dados',
    format: 'Formato',
    categories: 'Categorias',
    defaultCategory: '(Nenhuma)',
    series: 'Séries',
    xyValues: 'Valores X Y',
    paired: 'Modo pareado',
    axis: 'Eixo',
    navigator: 'Navegador',
    color: 'Cor',
    thickness: 'Espessura',
    xType: 'Tipo X',
    automatic: 'Automático',
    category: 'Categoria',
    number: 'Número',
    time: 'Tempo',
    xRotation: 'Rotação X',
    yRotation: 'Rotação Y',
    ticks: 'Ticks',
    width: 'Largura',
    height: 'Altura',
    length: 'Longitude',
    padding: 'Preenchimento',
    spacing: 'Espaço',
    chart: 'Gráfico',
    title: 'Título',
    titlePlaceholder: 'Título do Gráfico - duplo clique para editar',
    background: 'Fundo',
    font: 'Fonte',
    top: 'Superior',
    right: 'Direita',
    bottom: 'Inferior',
    left: 'Esquerda',
    labels: 'Etiquetas',
    size: 'Tamanho',
    minSize: 'Tamanho mínimo',
    maxSize: 'Tamanho máximo',
    legend: 'Legenda',
    position: 'Posição',
    markerSize: 'Marcador',
    markerStroke: 'Traço',
    markerPadding: 'Preenchimento',
    itemSpacing: 'Espaço do ítem',
    itemPaddingX: 'Preenchimento X do ítem',
    itemPaddingY: 'Preenchimento Y do ítem',
    layoutHorizontalSpacing: 'Espaçamento horizontal',
    layoutVerticalSpacing: 'Espaçamento vertical',
    strokeWidth: 'Largura do traço',
    offset: 'Deslocamento',
    offsets: 'Deslocamento',
    tooltips: 'Tooltips',
    callout: 'Rótulo',
    markers: 'Marcadores',
    shadow: 'Sombra',
    blur: 'Esfumaçado',
    xOffset: 'Deslocamento X',
    yOffset: 'Deslocamento Y',
    lineWidth: 'Largura de linha',
    normal: 'Normal',
    bold: 'Negrito',
    italic: 'Itálico',
    boldItalic: 'Negrito Itálico',
    predefined: 'Predefinido',
    fillOpacity: 'Opacidade de preenchimento',
    strokeOpacity: 'Opacidade de linha',
    histogramBinCount: 'Total',
    columnGroup: 'Coluna',
    barGroup: 'Barra',
    pieGroup: 'Pizza',
    lineGroup: 'Linha',
    scatterGroup: 'X Y (Disperção)',
    areaGroup: 'Área',
    histogramGroup: 'Histograma',
    groupedColumnTooltip: 'Agrupado',
    stackedColumnTooltip: 'Empilhado',
    normalizedColumnTooltip: '100% Empilhado',
    groupedBarTooltip: 'Agrupado',
    stackedBarTooltip: 'Empilhado',
    normalizedBarTooltip: '100% Empilhado',
    pieTooltip: 'Pizza',
    doughnutTooltip: 'Donut',
    lineTooltip: 'Linha',
    groupedAreaTooltip: 'Área',
    stackedAreaTooltip: 'Empilhado',
    normalizedAreaTooltip: '100% Empilhado',
    scatterTooltip: 'Disperção',
    bubbleTooltip: 'Bolha',
    histogramTooltip: 'Histograma',
    noDataToChart: 'Não há dados disponíveis para criar o gráfico.',
    pivotChartRequiresPivotMode: 'Gráfico de Pivô requer ativar o modo Pivô.',
}
