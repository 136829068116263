<template>
  <div v-if="params.data" class="flex items-center">
    <router-link :to="url" @click.stop.prevent class="text-inherit hover:text-primary">{{ params.value }}</router-link>
  </div>
</template>

<script>
export default {
  name: 'CellRendererName',
  computed: {
    url () {
      if (this.params.data) {
        return `/vehicle/edit/${this.params.data.id}`
      }
    }
  }
}
</script>
