var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.params.data
    ? _c(
        "div",
        { staticClass: "flex items-center" },
        [
          _c(
            "router-link",
            {
              staticClass: "text-inherit hover:text-primary",
              attrs: { to: _vm.url },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                },
              },
            },
            [_vm._v(_vm._s(_vm.params.value))]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }