var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: { direction: _vm.$vs.rtl ? "rtl" : "ltr" } },
    [
      _c("feather-icon", {
        attrs: {
          title: "Manutenção",
          icon: "ToolIcon",
          svgClasses: "h-5 w-5 ml-2 hover:text-primary cursor-pointer",
        },
        on: { click: _vm.maintenance },
      }),
      _c("feather-icon", {
        attrs: {
          title: "Abastecimento",
          icon: "BatteryChargingIcon",
          svgClasses: "h-5 w-5 ml-2 hover:text-primary cursor-pointer",
        },
        on: { click: _vm.fuelSupply },
      }),
      _c("feather-icon", {
        attrs: {
          title: "Ordem de Serviço",
          icon: "DollarSignIcon",
          svgClasses: "h-5 w-5 ml-2 hover:text-primary cursor-pointer",
        },
        on: { click: _vm.serviceOrder },
      }),
      _c("feather-icon", {
        attrs: {
          title: "Editar",
          icon: "Edit3Icon",
          svgClasses: "h-5 w-5 ml-2 hover:text-primary cursor-pointer",
        },
        on: { click: _vm.editRecord },
      }),
      _c("feather-icon", {
        attrs: {
          title: "Excluir",
          icon: "Trash2Icon",
          svgClasses: "h-5 w-5 ml-2 hover:text-danger cursor-pointer",
        },
        on: { click: _vm.deleteRecord },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }